<template>
  <v-container fluid>
    <v-row v-if="!editCard">
      <v-col cols="12" sm="8" md="6" lg="4">
        <div>
          <span class="mr-1">Назва:</span>
          <span class="font-weight-medium">{{ currentCompany.name }}</span>
        </div>

        <div>
          <span class="mr-1">ID:</span>
          <span class="font-weight-medium">{{ currentCompany.id }}</span>
        </div>

        <div>
          <span class="mr-1">Опис:</span>
          <span class="font-weight-medium">{{ currentCompany.description }}</span>
        </div>

        <div>
          <span class="mr-1">Власник:</span>
          <span class="font-weight-medium">{{ ownerFullName }}</span>
        </div>

        <div>
          <span class="mr-1">Телефон:</span>
          <span class="font-weight-medium">{{ currentCompany.owner.phone }}</span>
        </div>

        <div>
          <span class="mr-1">ЄДРПОУ (IПН):</span>
          <span class="font-weight-medium">{{ currentCompany.tin }}</span>
        </div>

        <div>
          <span class="mr-1">Сайт:</span>
          <a :href="currentCompany.site" target="_blank">{{ currentCompany.site }}</a>
        </div>
      </v-col>

      <v-col cols="12" sm="8" md="6" lg="4">
        <v-avatar v-if="currentItem.logo" class="mb-3" size="100">
          <img :src="`${baseURL}/${currentItem.logo}`" alt="logo" />
        </v-avatar>
      </v-col>
    </v-row>

    <v-row v-else justify="space-between">
      <v-col cols="12" sm="8" md="6" lg="4">
        <div class="d-flex align-baseline">
          <span class="mr-1">Назва:</span>
          <v-text-field v-model.trim="currentCompany.name" dense hide-details></v-text-field>
        </div>

        <div class="d-flex align-baseline">
          <span class="mr-1">Опис:</span>
          <v-text-field v-model="currentCompany.description" dense hide-details></v-text-field>
        </div>

        <div class="d-flex align-baseline">
          <span class="mr-1 nowrap-text">Власник компанії:</span>
          <PagingAutocomplete
            v-model="currentCompany.owner"
            :options="users"
            :options-meta="usersMeta"
            :options-params="usersOptions"
            @change="(val) => (currentCompany.owner = val)"
            no-filter
            item-text="phone"
            item-value="id"
            clearable
            hide-details
            dense
          >
            <template v-slot:selection="{ item }"> {{ item.first_name }} {{ item.last_name }}</template>
            <template v-slot:item="{ item }"> {{ item.first_name }} {{ item.last_name }} ({{ item.phone }}) </template>
          </PagingAutocomplete>
        </div>

        <div class="d-flex align-baseline">
          <span class="mr-1">ЄДРПОУ (IПН):</span>
          <v-text-field v-model.trim="currentCompany.tin" dense hide-details></v-text-field>
        </div>

        <div class="d-flex align-baseline">
          <span class="mr-1">Сайт:</span>
          <v-text-field v-model="currentCompany.site" dense hide-details></v-text-field>
        </div>
      </v-col>

      <v-col cols="12" sm="8" md="6" lg="4">
        <v-avatar v-if="currentItem.logo" class="mb-3" size="100">
          <img :src="`${baseURL}/${currentItem.logo}`" alt="logo" />
        </v-avatar>
        <FileUploader
          @updateUpload="reloadCompany"
          :companyId="currentCompany.id"
          accept="image/*"
          actionType="uploadCompanyLogo"
          label="Вибрати лого компанії"
          prepend-icon="mdi-camera"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-divider v-if="!editCard" class="mb-2"></v-divider>
        <CardAddress :is-data-saved="isDataSaved" :edit-card="editCard"></CardAddress>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Info',

  components: {
    PagingAutocomplete: () => import('@/components/common/PagingAutocomplete'),
    FileUploader: () => import('@/components/common/FileUploader'),
    CardAddress: () => import('@/components/partials/CardAddress'),
  },

  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    isCurrentCompanyCard: {
      type: Boolean,
      required: true,
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentTabName: {
      required: true,
      default: null,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      baseURL: process.env.VUE_APP_BASE_API_URL,
      currentCompany: {},
      usersOptions: null,
      isUserLoading: false,
    }
  },

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('users', ['users', 'usersMeta']),

    currentCompanyId() {
      return this.$route.params.id
    },
    ownerFullName: {
      get() {
        return `${this.currentCompany.owner.first_name} ${this.currentCompany.owner.last_name}`
      },
      /*set(newValue) {
        const names = newValue.split(' ')
        this.currentCompany.owner.first_name = names[0]
        this.currentCompany.owner.last_name = names[names.length - 1]
      },*/
    },
  },

  watch: {
    isDataSaved(val) {
      if (val && this.currentTabName === 'info') {
        this.$emit('saveData', this.currentCompany)
      }
    },
    'company.id'(val) {
      if (val) this.initialize()
    },
    editCard(val) {
      if (!val) this.initialize()
    },
  },

  methods: {
    ...mapActions('companies', ['loadCurrentCompany', 'getSelectedCompany']),
    ...mapActions('users', ['loadUsers']),

    initialize() {
      this.currentCompany = { ...this.currentItem }
      this.usersOptions = {
        loadingFunction: this.loadUsers,
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
        },
      }
    },
    async reloadCompany() {
      this.isCurrentCompanyCard
        ? await this.loadCurrentCompany(this.currentItem.id)
        : await this.getSelectedCompany(this.currentItem.id)
      this.initialize()
    },
  },
}
</script>

<style scoped lang="scss">
label {
  cursor: pointer;
}
.nowrap-text {
  white-space: nowrap;
}
</style>
